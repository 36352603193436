
(function($, window) {

    const deviceType = window.screen.width < 768 ? 'mobile' : 'desktop';

    let videos = $('video[data-src]');

    const permittedTypes = ['webm', 'mp4'];

    videos.each((i, el) => {
        let video = $(el);
        for (let src of video.data('src')[deviceType]) {
            let ext = src.split('.').pop();
            
            if (permittedTypes.includes(ext)) {
                let source = $('<source/>').attr({
                    src: src,
                    type: 'video/' + ext
                });
    
                video.append(source);
            }
        }
    });
    
})(jQuery, window);
