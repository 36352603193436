
(function($, window) {

  $(document).ready(function() {
    $('#site-select-button').click(function(e) {
      e.preventDefault();
      window.location = $('#site-select').val();
    })
  });
  
})(jQuery, window);
