function throttle(callback, limit) {
    var wait = false;
    return function (...args) { 
        if (!wait) {
            callback(...args);
            wait = true; 
            setTimeout(function () { 
                wait = false; 
            }, limit);
        }
    }
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
