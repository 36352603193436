(function($, window){

    // On document ready
    $(function(){
        
    });
    

    // Links

    $('a[href*="#"]').click(function(e){
        const dest = $(this).attr('href');
        if ($(dest).length) {
            e.preventDefault();
            gsap.to(window, {
                duration: 0.3, 
                scrollTo: dest,
                ease: "power3.out"
            });
        }
    });

})(jQuery, window);
