(function($, window){

    // On document ready
    $(function(){
        
        // Scroll effects
        {
            
            let iconBullets = $('.icon-bullets-c .icon-bullet, .ingredients .list, .ingredients .ingredient');
            let iconBulletsExist = !!iconBullets.length;
            let bulletVis = [];
            let scrollElement;

            if (iconBulletsExist) {
                if ($('body').hasClass('para-drop')) {
                    scrollElement = $('#wrapper')[0];
                }
                else {
                    scrollElement = window;
                }
                scrollElement.addEventListener('scroll', throttle(onScroll, 100), false);
                scrollElement.addEventListener('scroll', debounce(onScroll, 100), false);
                scrollElement.addEventListener('resize', debounce(onScroll, 100), false);

                iconBullets.each((i,el)=>{
                    bulletVis.push(false);
                    $(el).addClass('preveal');
                });
            }

            function pastBottomEdge(el) {
                let viewportHeight = (scrollElement.innerHeight || document.documentElement.clientHeight);
                let bulletBounds = el.getBoundingClientRect();
                return bulletBounds.bottom - viewportHeight < 0;
            }

            function onScroll() {
                iconBullets.each((i,el)=>{
                    let bulletsVisible = bulletVis[i];
                    const newBulletsVisible = pastBottomEdge(el);
                    if (!bulletsVisible && bulletsVisible != newBulletsVisible) {
                        bulletVis[i] = newBulletsVisible;
                        $(el).toggleClass('preveal', bulletsVisible);
                    }

                });
            }
    
            $(window).load(onScroll);
        }
        
    });

})(jQuery, window);
