(function($) {

  'use strict';

  $(document).ready(function() {

      // There may be multiple 'nav' elements - change this to depend on your circumstances
      $('button.hamburger').on('click', function() {

          $('button.hamburger').toggleClass('is-active');
          $('body').toggleClass('show-mobile-menu');

      });

      $('.hero-video-link').on('click', function() {
        $('.hero-video-lightbox').addClass('active');
      });

      $('.hero-video-lightbox-close').on('click', function() {
        $('.hero-video-lightbox').removeClass('active');
      })

      $(document).keydown(function(event) {
        if(event.which == 27) $('.hero-video-lightbox').removeClass('active');
      });

      $('.trial_cta_button').on('touchstart touchend', function(e) {
        $(this).toggleClass('hover-effect');
      });

      $('.ultra-stories-carousel').marmeloSlide({containerClass: '.ultra-stories-carousel-content', itemClass: '.slide', minNumber: 1})

  });

})(jQuery);