// Marmelo Slide
// Authors: 	Luke Alexander (luke@marmelodigital.com) 
//            Abhinav Bajpai (abhinav@marmelodigital.com)
// Date:	20/6/2019
// Version:	0.0.1
//
// Creates a simple slider that can be themed
// Usage: $(<selector>).marmeloSlide({
//            containerClass: <class of div that wraps the slide divs>,           
//            itemClass: <class of the slide divs  - must be direct children of containerClass>,
//            *minNumber: <minimum number of items that must be present to bother>,
//            *buttons: <boolean flag.  If true the previous and next buttons are shown>,
//            *wrapAround: <boolean flag.  If true prev and next buttons will wrap around when scrolling past the last/first slide>,
//            *classes: {past: <class for previous slides>, show: <class for current slide>, future: <class for next slides>}
//         })
//         *: default values provided
//          
//         Once initialised you can use the member functions like any jQuery plugin, e.g.:
//         $(selector).marmeloSlide('setIndexAndSlide', 4);   // Slide to the 5th item
//         $(selector).marmeloSlide('navigate' -1);           // Simulate 'prev' button click


;(function ( $, window, document, undefined ) {

	'use strict';
		
		var pluginName = 'marmeloSlide',
            defaults = {
                minNumber: 1,
                pagination: true,
                buttons: true,
                wrapAround: true,
                visible: 1,
                swipe: true,
                classes: {
                  past: 'past',
                  show: 'show',
                  future: '',
                }
		    };

		// The actual plugin constructor
		function marmeloSlide ( element, options ) {
                this.settings = $.extend(true, {}, defaults, options);
                this.fullItemClass = this.settings.containerClass + '>' + this.settings.itemClass;
                this.element = element;
				this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend(marmeloSlide.prototype, {
            init: function () {
                var $element = $(this.element);
                var plugin_element = this;
                var date = new Date();
                var uniqueClass = "uq-" + date.getMilliseconds() + Math.round(Math.random() * 999999);
                var uniqueClassId = uniqueClass + "-item";
                this.currentIndex = 0;
                $element.addClass(uniqueClass).addClass('marmelo-slide-loaded');        
                this.$items = $element.find(plugin_element.fullItemClass);
                this.$items.slice(0, plugin_element.settings.visible).addClass(plugin_element.settings.classes.show);       
                
                if(plugin_element.settings.pagination){
                    var $pagination = $("<div class='pagination'></div>");
                    var i = 0;        

                    this.$items.each(function() {
                        $(this).addClass(uniqueClassId);
                        var thislink = $("<i class='paginate-link" + ((i==0) ? " active" : "") + "'></i>");
                        thislink.on('click', function() {
                            plugin_element.setIndexAndSlide($(this).index());
                        });
                        i++;
                        $pagination.append(thislink);                
                    })
                    this.$pagination = $pagination;
                    $element.append($pagination);
                }

                if(plugin_element.settings.buttons) {
                    var prev = $("<div class='slide-button previous'></div>");
                    var next = $("<div class='slide-button next'></div>");
                    $element.prepend(prev);
                    $element.append(next);
                    prev.click(function() { plugin_element.navigate(-1); });
                    next.click(function() { plugin_element.navigate(1); });
                }

                if(plugin_element.settings.swipe) {
                  $element.on("swiperight", function(event) {
                    console.log(event, 'right');
                    plugin_element.navigate(-1);
                  })
                  $element.on("swipeleft", function(event) {
                    console.log(event, 'left');
                    plugin_element.navigate(1);
                  })
                }

                return this;
            },

            setIndexAndSlide: function(newIndex) {
              var $element = $(this.element);
              var plugin_element = this;
              if(newIndex >= 0 && newIndex < plugin_element.$items.length - plugin_element.settings.visible + 1)
                plugin_element.currentIndex = newIndex;
              else {
                if(newIndex < 0) 
                  plugin_element.currentIndex = (plugin_element.settings.wrapAround) ? plugin_element.$items.length - plugin_element.settings.visible : 0;
                if(newIndex >= plugin_element.$items.length -plugin_element.settings.visible)
                  plugin_element.currentIndex = (plugin_element.settings.wrapAround) ? 0 : plugin_element.$items.length - plugin_element.settings.visible;
              }
              var slide_classes = plugin_element.settings.classes;

              var $child_elements = $element.find(plugin_element.fullItemClass);

              $child_elements.slice(plugin_element.currentIndex, plugin_element.currentIndex + plugin_element.settings.visible).removeClass(slide_classes.past).addClass(slide_classes.show).removeClass(slide_classes.future);
              $child_elements.slice(0, plugin_element.currentIndex).addClass(slide_classes.past).removeClass(slide_classes.show).removeClass(slide_classes.future);
              $child_elements.slice(plugin_element.currentIndex + plugin_element.settings.visible, $child_elements.length).removeClass(slide_classes.past).removeClass(slide_classes.show).addClass(slide_classes.future);

              plugin_element.updatePagination();
            },

            navigate: function(direction) {
                if(direction === undefined) direction = 1;
                var plugin_element = this;
                plugin_element.setIndexAndSlide(plugin_element.currentIndex += direction);
            },

            updatePagination: function() {
              var $element = $(this.element);
              var plugin_element = this;
              plugin_element.$pagination.children().removeClass('active');
              plugin_element.$pagination.children(':eq(' + plugin_element.currentIndex + ')').addClass('active');
            }
            
            
		});


		$.fn[ pluginName ] = function ( options ) {
				var args = arguments;
				return this.each(function() {
						if ( !$.data( this, 'plugin_' + pluginName ) ) {
                                if($(this).prop('tagName').toLowerCase() != 'div'){
                                    console.log('This plugin can only be used with "div" elements');
                                    return this;
                                }
                                if(!$.isNumeric(options.minNumber)) {
                                    console.log('Please provide a minimum number of child elements to carouse with')
                                    return this;
                                }
                                if($(this).find(options.containerClass + '>' + options.itemClass).length < options.minNumber) {
                                    console.log('Not enough elements to carouse');
                                }
								return $.data( this, 'plugin_' + pluginName, new marmeloSlide( this, options ) );
						} 
						else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
								var returns;							
                				var instance = $.data(this, 'plugin_' + pluginName);
                				if (!(instance instanceof marmeloSlide)) 
                				{
                					console.error('This is not an Marmelo Slider object');
                					return this;
                				}
                				if(typeof instance[options] != 'function')
                				{
                					console.error('Marmelo Slider does not contain a function ' + options);
                					return this;
                				}
                				
                				returns = instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
                				
                				if (options === 'destroy') {
                  					$.data(this, 'plugin_' + pluginName, null);
                				}
                				return returns !== undefined ? returns : this;
						}
				});
		};

})( jQuery, window, document );