(function($, window){

    $('sup:contains(®)').addClass('copymark');
    $('sup:contains(MD)').addClass('copymark');


    // Link superscripts that aren't already inside a hyperlink 
    // to the globally defined reference page
    if(!$('body').hasClass('page-template-ultrastories')) {
        $('sup')
            .not('a sup')
            .not('.pre-footer-area sup')
            .not(':contains(®)')
            .not(':contains(†)')
            .not(':contains(*)')
            .addClass('ref-sup')
            .wrap(`<a class="reflink" href="${FJ_DATA.reference_url}"></a>`);
    }

})(jQuery, window);
