(function($, window){

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return;
    }
    if (!$('body').hasClass('page-template-ecp')) {
        return;
    }


    // On document ready
    $(function(){
    
        let dropManager = new DropManager({
            density: 80
        });

        window.addEventListener('resize', debounce(()=>{dropManager.reset()}, 100), false);
    });



    function Drop(manager, x, y, droption) {
        const rootURI = FJ_DATA.stylesheet_uri;
        let visible = false;

        this.el = $('<div/>')
            .addClass(`drop ${droption.img}`)
            .css('transform', `translate3d(${x}px, ${y}px, ${droption.pos}px) scale(${droption.scale})`);
        // this.el = $('<div/>')
        //     .addClass(`drop ${droption.img}`)
        //     .css('transform', `translate3d(0, ${y}px, 0) scale(${droption.scale})`);
        
        this.options = droption;
    }

    class DropManager {
        
        constructor(config) {
            this.config = config;
            this.init();
        }

        init() {

            $('.dropHolder').remove();

            const contentWidth = 1200;
            const viewportWidth = document.documentElement.clientWidth;
            if (viewportWidth <= contentWidth) {
                return;
            }
            const canvasHeight = $('#wrapper')[0].scrollHeight;
            const dropHolderBG = $('<div/>').addClass('dropHolder').css('height', canvasHeight).prependTo('#wrapper');
            const dropHolderFG = $('<div/>').addClass('dropHolder').css('height', canvasHeight).appendTo('#wrapper');

            this.drops = [];
            this.droptions = [
                {
                    img: 'bg',
                    pos: -30,
                    weight: 3,
                    scale: 1
                },
                {
                    img: 'mg-2',
                    pos: -20,
                    weight: 1,
                    scale: 1
                },
                {
                    img: 'mg-1',
                    pos: -10,
                    weight: 1,
                    scale: 0.9
                },
                {
                    img: 'fg-2',
                    pos: 10,
                    weight: 1,
                    scale: 0.8
                },
                {
                    img: 'fg-1',
                    pos: 25,
                    weight: 0.7,
                    scale: 0.6
                }
            ];

            const scrollTop = $('#wrapper')[0].scrollTop;
            
            const stepDistance = (1600 / (viewportWidth - contentWidth) ) *  1000 / this.config.density;
            const headingYPositions = $('.container-inner h2, .container-inner h3').toArray().map(el => scrollTop + el.getBoundingClientRect().bottom + 30);
            

            for (let posY=0; posY<canvasHeight; posY+=stepDistance) {
                
                const minDistance = Math.min(...headingYPositions.map(hPos => Math.abs(hPos - posY)));
                

                if (minDistance < 220) {
                    let droption = this.getDroption();
                    const depthCorrection = 5 * droption.pos;
                    const exclusionZone = contentWidth - 2 * depthCorrection;
                    const availableArea = viewportWidth - exclusionZone - 2 * depthCorrection;
                    console.log(availableArea);
                    let posX = Math.random() * availableArea;
                    posX += posX > availableArea/2 ? exclusionZone + depthCorrection : depthCorrection; 

                    let drop = new Drop(this, posX, posY, droption);
                    this.drops.push(drop);
                }

            }
            this.drops.sort((a, b) => a.options.pos - b.options.pos);
            
            // dropHolderBG.append(this.drops.filter(d => d.options.pos <= 0).map(d => d.el));
            // dropHolderFG.append(this.drops.filter(d => d.options.pos > 0).map(d => d.el));
            dropHolderFG.append(this.drops.map(d => d.el));
        }

        updateDrops(scrollPos) {
            // console.log(scrollPos);
            // for(let i=0; i<this.drops.length; i++) {

            // }

            // for (let i=this.drops.length; i--; ) {
            //     add( array[i] );
            // }
        }

        getDroption() {
            const range = this.droptions.reduce((r, cur) => r + cur.weight, 0);
            const point = Math.random() * range;
            let pointer = 0;
            let drop;

            for (let i = 0; pointer < point; i++) {
                drop = this.droptions[i];
                pointer += drop.weight;
            }
            
            return drop;
        }

        reset() {
            this.init();
        }
    }

    
    $('body').addClass('para-drop');
   
 
})(jQuery, window);
