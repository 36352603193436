(function($, window){

    // On document ready
    $(function(){
        
    });

    // Tile Expansion

    const deviceType = $('body').innerWidth() < 768 ? 'mobile' : 'desktop';

    let tileButtonsAll = $('#content-main')
        .find(`.faqs .q, 
               .exp-icon > .icon-bullet`);

    let tileExpanders = {
        mobile: $(`.mob-expandable > .expandable-logo > .logo,
            .expandable-logo.mob-expandable > .logo, 
            .expandable-icon.mob-expandable > .icon-bullet, 
            #content-main .tiles .tile .text.mob-expandable`),
        desktop: $(`.dt-expandable > .expandable-logo > .logo,
            .expandable-logo.dt-expandable > .logo, 
            .expandable-icon.dt-expandable > .icon-bullet, 
            #content-main .tiles .tile .text.dt-expandable`)
    };

    tileButtonsAll.on( 'click', expandClickHandler);
    tileExpanders[deviceType].on( 'click', expandClickHandler);

    // console.log(tileExpanders[deviceType]);

    function expandClickHandler(e) {


        e.preventDefault();
        const button = $(e.currentTarget);

        button.toggleClass('expanded');

        if (!button.hasClass('text')) {
            if (button.hasClass('expanded')) {
                button.next('.text').slideDown({duration: 400, easing: 'easeOutExpo'});
            }
            else {
                button.next('.text').slideUp({duration: 400, easing: 'easeOutExpo'});
            }
        }
    }
    
})(jQuery, window);
